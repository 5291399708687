import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import TopMenu from "./Topmenu";

export default function Layout(props) {
    return (
        <>
            <Header/>
            <TopMenu />
            <main>
                {props.children}
            </main>
            <Footer/>
            <div className={`${process.env.CSS_DEV}`}></div>
        </>
    )
}