import React from "react";
import {graphql, Link, useStaticQuery} from "gatsby";
import Logo from "../Logo/logo";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faFacebook, faInstagram} from "@fortawesome/free-brands-svg-icons";
import {faEnvelope, faPhone} from "@fortawesome/free-solid-svg-icons";


const TopMenu = () => {
    const data = useStaticQuery(graphql`
       query {
            site{
                siteMetadata {
                    author,
                    phonePetr,
                    email
                }
            }
        }
   `)
    var phone = 'tel:+420 '+data.site.siteMetadata.phonePetr;
        return (

            <nav className="navbar navbar-expand-md navbar-light navbar-haxo">
                <div className="container-xl container-fluid ">
                    <Link to={"/"} className="navbar-brand ">
                        <Logo/>
                    </Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarsExampleDefault" aria-controls="navbarsExampleDefault"
                            aria-expanded="false"
                            aria-label="Toggle navigation">
                        MENU
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="collapse navbar-collapse" id="navbarsExampleDefault">
                        <ul className="navbar-nav ms-auto mb-2 mb-md-0 mt-0 mt-md-2 ">
                            {/*
                            <li className="nav-item">
                                <Link to='#kdo-jsme' className="nav-link" activeClassName="active">Kdo jsme</Link>
                            </li>
                            <li className="nav-item">
                                <Link to='#sluzby' className="nav-link" activeClassName="active">Co děláme</Link>
                            </li>


                            <li className="nav-item">
                                <Link to='/reference' className="nav-link" activeClassName="active">Naše práce</Link>
                            </li>

                            <li className="nav-item me-lg-4">
                                <Link to='#kontakt' className="nav-link" activeClassName="active">Kontakt</Link>
                            </li>
                            */}

                            <li className="nav-item nav-contact">
                                <a href={phone} className="nav-link text-primary">
                                    <FontAwesomeIcon icon={faPhone}/>
                                </a>
                            </li>
                            <li className="nav-item nav-contact nav-contact-last">
                                <a href={data.site.siteMetadata.email}
                                   className="nav-link text-primary">
                                    <FontAwesomeIcon icon={faEnvelope}/>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>

        )
}

export default TopMenu

