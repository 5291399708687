import React from "react";
import logoImg from "../../assets/custom-style/images/logo.png";



export default function Logo() {
    return (
        <div className={"d-flex align-items-center"}>
            <img alt={"Haxo.cz"} title={"Haxo.cz"} width={"200"} className={"brand-img"} src={logoImg}/>
            {/*<div className={"brand-name"}>
               Haxo.cz
            </div>*/}
        </div>


    )
        ;
}
