/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {graphql, Link, useStaticQuery} from "gatsby";
import ContactCircle from "../Components/ContactCircle";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFacebook, faLinkedin, faLinkedinIn, faTwitter, faYoutube} from "@fortawesome/free-brands-svg-icons";
import Logo from "../Logo/logo";

const Footer = () => {
    const data = useStaticQuery(graphql`
       query {
            site{
                siteMetadata {
                    author,
                    email,
                    phoneJan,
                    phonePetr,
                    emailJan,
                    emailPetr
                }
            }
        }
   `)
    var telJan = 'tel:' + data.site.siteMetadata.phoneJan;
    var telPetr = 'tel:' + data.site.siteMetadata.phonePetr;
    var mailto = 'mailto:' + data.site.siteMetadata.email;
    var mailtoJan = 'mailto:' + data.site.siteMetadata.emailJan;
    var mailtoPetr = 'mailto:' + data.site.siteMetadata.emailPetr;
    return (
        <footer className="footer mt-auto">

            <div className="container">

                <div className="row">
                    <div className="col-12 col-md-3"><Logo/></div>
                    <div className="col-12 col-md-3">
                        <h5>Kancelář</h5>
                        <p>
                            <p>
                                Městské sady 463/2 <br/>
                                284 01 Kutná Hora
                            </p>
                            <p>
                                <a href={mailto}>{data.site.siteMetadata.email}</a>
                            </p>
                        </p>
                    </div>
                    <div className="col-12 col-md-3">

                        <h5>Kontakty</h5>
                        <p>
                            <strong>Jan Harsa</strong><br/>
                            <a href={telJan}>+420 {data.site.siteMetadata.phoneJan}</a> <br/>
                            <a href={mailtoJan}>{data.site.siteMetadata.emailJan}</a>
                        </p>
                        <p>
                            <strong>Petr Balvín</strong><br/>
                            <a href={telPetr}>+420 {data.site.siteMetadata.phonePetr}</a> <br/>
                            <a href={mailtoPetr}>{data.site.siteMetadata.emailPetr}</a>
                        </p>
                    </div>


                    <div className="col-12 col-md-3">
                        <h5>Fakturační údaje</h5>
                        <p>
                            <strong>Haxo.cz s.r.o.</strong><br/>
                            Rybná 716/24<br/>
                            110 00 Praha 1
                        </p>
                        <p>
                            IČ: 03484157<br/>
                            DIČ: CZ03484157<br/>
                        </p>
                        <p>Číslo účtu: <strong>2300679296/2010 </strong>
                            (Fio banka a.s. )
                            <br/>
                            ID datové schránky: 4ucq54v
                        </p>
                    </div>
                </div>


            </div>
            <div className="bg-light-border">
                <div className="container py-4">

                    <p className="float-end mb-1">
                        <a href="#">Nahoru</a>
                    </p>
                    <p className="small">{data.site.siteMetadata.author} © {new Date().getFullYear()}</p>
                </div>
            </div>
        </footer>
    );
}

export default Footer
